import { NgModule, APP_INITIALIZER } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorsModule } from './errors/errors.module';
import { AppConfigService } from './services/appConfig/appConfigService';
import { LocaleData, AppSettings } from '@shared/generalInterfaces';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    ErrorsModule,
    TranslateModule,
    AppRoutingModule
  ],
  providers: [
    {
			provide: APP_INITIALIZER,
			multi: true,
			deps: [
        AppConfigService
			],
			useFactory: (appConfigService: AppConfigService) => {
				return () => {
					return appConfigService.loadAppSettings().then(() => {}).catch(console.error);
				};
			}
    },
    {
			provide: APP_INITIALIZER,
			multi: true,
			deps: [
        AppConfigService
			],
			useFactory: (appConfigService: AppConfigService) => {
				return () => {
					return appConfigService.loadLocaleData().then(() => {}).catch(console.error);
				};
			}
		}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
