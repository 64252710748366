import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './pageNotFound.component';
import { ErrorPage } from './error-page.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	imports: [
		SharedModule,
		RouterModule
	],
	declarations: [
		PageNotFoundComponent,
		ErrorPage
	],
	exports: [ PageNotFoundComponent ]
})
export class ErrorsModule {}
