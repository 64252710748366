import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './errors/pageNotFound.component';


const routes: Routes = [
  {
    path: 'evx-downloads-Plus',
    loadChildren: './downloadsAndResources/downloadsAndResources.module#DownloadsAndResourcesModule'
  },
  {
    path: 'evx-downloads',
    loadChildren: './downloadsAndResources/downloadsAndResources.module#DownloadsAndResourcesModule'
  },
  {
    path: 'downloads',
    loadChildren: './downloadsAndResources/downloadsAndResources.module#DownloadsAndResourcesModule'
  },
  { path: 'pagenotfound', component: PageNotFoundComponent, pathMatch: 'full', data: { title: '404' } },
  { path: '**', component: PageNotFoundComponent, data: { title: '404' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
