/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error-page.component";
var styles_ErrorPage = [i0.styles];
var RenderType_ErrorPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorPage, data: {} });
export { RenderType_ErrorPage as RenderType_ErrorPage };
export function View_ErrorPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "error-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "error-reason"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statusCode; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errorHeader; _ck(_v, 4, 0, currVal_1); }); }
export function View_ErrorPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "eup-error-page", [], null, null, null, View_ErrorPage_0, RenderType_ErrorPage)), i1.ɵdid(1, 2146304, null, 0, i2.ErrorPage, [], null, null)], null, null); }
var ErrorPageNgFactory = i1.ɵccf("eup-error-page", i2.ErrorPage, View_ErrorPage_Host_0, { statusCode: "statusCode", errorHeader: "errorHeader", errorDescription: "errorDescription", errorDescriptionToDisplay: "errorDescriptionToDisplay", errorDescriptionToDisplay2: "errorDescriptionToDisplay2" }, {}, []);
export { ErrorPageNgFactory as ErrorPageNgFactory };
