import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, AppSettings, LocaleData } from '../../shared/generalInterfaces';
import { tap, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
  export class AppConfigService {
    private appConfig: AppConfig = new AppConfig();
  
    constructor(private http: HttpClient) { }

    loadAppSettings(): Promise<AppSettings> {
      return this.http.get('/assets/config/appSettings.json')
              .pipe(
                tap((data: AppSettings) => this.appConfig.appSettings = data)
              ).toPromise();
    }

    loadLocaleData(): Promise<LocaleData> {
      return this.http.get('/assets/locale/locale.json')
      .pipe(
        tap((localeData: LocaleData) => this.appConfig.localeData = localeData)
      ).toPromise();
    }

    get appSettings(): AppSettings {
      if (!this.appConfig.appSettings) {
        throw Error('App Settings not loaded!');
      }
  
      return this.appConfig.appSettings;
    }

    get localeData(): LocaleData {
      if (!this.appConfig.localeData) {
        throw Error('Locale Data not loaded!');
      }
  
      return this.appConfig.localeData;
    }
  }