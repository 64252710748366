import { AppConfigService } from './services/appConfig/appConfigService';
var ɵ0 = function (appConfigService) {
    return function () {
        return appConfigService.loadAppSettings().then(function () { }).catch(console.error);
    };
}, ɵ1 = function (appConfigService) {
    return function () {
        return appConfigService.loadLocaleData().then(function () { }).catch(console.error);
    };
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
