import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap';

const sharedModules = [
	CommonModule,
	TranslateModule
];

const sharedComponents = [
];

@NgModule({
	declarations: [...sharedComponents],
	imports: [...sharedModules, BsDropdownModule.forRoot()],
	exports: [...sharedModules, ...sharedComponents, BsDropdownModule]
})
export class SharedModule {}
