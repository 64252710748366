import { Component, Input, AfterContentChecked } from '@angular/core';

@Component({
	selector: 'eup-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: [ './error-page.component.scss' ]
})
export class ErrorPage implements AfterContentChecked {
	@Input() statusCode: string;
	@Input() errorHeader: string;
	@Input() errorDescription: string;
	@Input() errorDescriptionToDisplay: string;
	@Input() errorDescriptionToDisplay2: string;
	linkToText = 'login';

	constructor() {}

	ngAfterContentChecked(): void {
		if (!!this.errorDescription && this.errorDescription.length > 0) {
			const homeLinkStartIndex = this.errorDescription.indexOf('<a>');
			const homeLinkEndIndex = this.errorDescription.indexOf('</a>');
			this.errorDescriptionToDisplay = this.errorDescription.substring(0, homeLinkStartIndex);
			this.errorDescriptionToDisplay2 = this.errorDescription.substring(homeLinkEndIndex + 4);
		}
	}
}
