import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from './services/appConfig/appConfigService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  lang = 'en-US';

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.setLang();
  }

  private setLang(): void {
    const language = window.navigator.userLanguage || window.navigator.language;    
		if (language) {
			this.translateService.use(language);
		} else {
			this.translateService.use(this.lang);
		}
	}
}
